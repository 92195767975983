@if ({isLoading: isLoading$ | async, feedback: feedback$ | async}; as ctx) {
  <span class="relative">
    <span [class.invisible]="hasFeedback(ctx.feedback) || ctx.isLoading">
      <ng-content></ng-content>
    </span>
    @if (ctx.isLoading) {
      <span class="absolute left-0 top-0 w-full" #spinner>
        <span class="flex justify-center">
          @if (loadingLabel) {
            <span class="mr-xs">{{ loadingLabel }}</span>
          }
          <cft-spinner></cft-spinner>
        </span>
      </span>
    }

    @if (hasFeedback(ctx.feedback) && !ctx.isLoading) {
      <span class="absolute left-0 top-0 w-full">
        @if (ctx.feedback === ButtonFeedback.Success) {
          <span
            class="flex items-center justify-center text-green-500"
            [ngClass]="{'text-green-500': successColour === 'green', 'text-white': successColour === 'white'}"
          >
            @if (successLabel) {
              <span class="mr-xs">{{ successLabel }}</span>
            }
            <fa-icon [icon]="successIcon" size="lg"></fa-icon>
          </span>
        }
        @if (ctx.feedback === ButtonFeedback.Failed) {
          <span class="flex justify-center">
            <fa-icon [icon]="failedIcon" size="lg"></fa-icon>
          </span>
        }
      </span>
    }
  </span>
}

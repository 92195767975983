import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'

@Component({
  selector: 'cft-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class SpinnerComponent {
  @Input() size: 'xs' | 'sm' | 'md' = 'sm'
}

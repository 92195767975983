import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  SkipSelf,
  ViewChildren,
} from '@angular/core'
import {Observable, Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'
import {faCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons/faTimesCircle'

@Component({
  selector: 'button[cft-button]',
  exportAs: 'cftButton',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnDestroy, AfterViewInit {
  @Input() disabledOnLoading = true
  @Input() loadingLabel?: string
  @Input() isLoading$?: Observable<boolean>
  @Input() feedback$?: Observable<ButtonFeedback>
  @Input() successLabel?: string
  @Input() @HostBinding('disabled') disabled
  @ViewChildren('spinner') spinner!: QueryList<ElementRef>
  @Input() failedIcon = faTimesCircle
  @Input() successColour: 'green' | 'white' = 'green'
  readonly successIcon = faCheckCircle

  readonly ButtonFeedback = ButtonFeedback
  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private elementRef: ElementRef,
    @SkipSelf() private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngAfterViewInit(): void {
    this.spinner.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      setTimeout(() => {
        this.disabled = this.disabledOnLoading && this.spinner.length > 0 ? 'disabled' : undefined
        this.cdr.markForCheck()
      })
    })
  }

  hasFeedback(feedback?: ButtonFeedback | null): boolean {
    return feedback === ButtonFeedback.Success || feedback === ButtonFeedback.Failed
  }
}

export enum ButtonFeedback {
  None = 1,
  Success,
  Failed,
}

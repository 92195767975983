@if (
  {
    button: _callToActionStyle === 'button' || _callToActionStyle === 'button-sm',
    link: _callToActionStyle === 'link'
  };
  as is
) {
  @switch (callToAction.type) {
    @case (phoneType) {
      <a
        class="w-full"
        [href]="contact | bgoTel"
        [attr.id]="anchorId"
        [ngClass]="{
          btn: is.button,
          link: is.link
        }"
        [class.btn-sm]="_callToActionStyle === 'button-sm'"
        [class.btn-hero-size]="_callToActionStyle === 'button'"
        [class.btn-hero]="is.button && buttonStyle === 'hero'"
        [class.btn-secondary]="is.button && buttonStyle === 'secondary'"
      >
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{icon: phoneIcon, iconPosition: 'left'}"
        ></ng-container>
      </a>
    }
    @case (emailType) {
      <a
        class="w-full"
        [href]="contact | bgoMailTo"
        [attr.id]="anchorId"
        [ngClass]="{
          btn: is.button,
          link: is.link,
          'btn-primary btn-primary-90': is.button && buttonStyle === 'primary'
        }"
        [class.btn-sm]="_callToActionStyle === 'button-sm'"
        [class.btn-hero-size]="_callToActionStyle === 'button'"
        [class.btn-hero]="is.button && buttonStyle === 'hero'"
        [class.btn-secondary]="is.button && buttonStyle === 'secondary'"
      >
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{icon: emailIcon, iconPosition: 'right'}"
        ></ng-container>
      </a>
    }
    @case (pathType) {
      <a
        class="w-full"
        (click)="scrollToAnchor($event, path, fragment)"
        [routerLink]="currentRoute !== path.routingPath ? pathService.localizedPathTo(path, {routeParams}) : undefined"
        [fragment]="currentRoute !== path.routingPath ? fragment : undefined"
        [attr.id]="anchorId"
        [ngClass]="{
          btn: is.button,
          link: is.link,
          'btn-primary btn-primary-90': is.button && buttonStyle === 'primary'
        }"
        [class.btn-sm]="_callToActionStyle === 'button-sm'"
        [class.btn-hero-size]="_callToActionStyle === 'button'"
        [class.btn-hero]="is.button && buttonStyle === 'hero'"
        [class.btn-secondary]="is.button && buttonStyle === 'secondary'"
      >
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{icon: pathIcon, iconPosition: 'right'}"
        ></ng-container>
      </a>
    }
    @case (urlType) {
      <a
        class="w-full"
        [href]="url"
        [attr.id]="anchorId"
        [ngClass]="{
          btn: is.button,
          link: is.link,
          'btn-primary btn-primary-90': is.button && buttonStyle === 'primary'
        }"
        [class.btn-sm]="_callToActionStyle === 'button-sm'"
        [class.btn-hero-size]="_callToActionStyle === 'button'"
        [class.btn-hero]="is.button && buttonStyle === 'hero'"
        [class.btn-secondary]="is.button && buttonStyle === 'secondary'"
        bgoOpenNewTab
      >
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{icon: urlIcon, iconPosition: 'left'}"
        ></ng-container>
      </a>
    }
    @case (whatsappType) {
      <a
        class="btn btn-hero-size btn-secondary w-full bg-[#25d366] text-white hover:bg-[#1da851]"
        [href]="url"
        [attr.id]="anchorId"
        [ngClass]="{
          btn: is.button,
          link: is.link
        }"
        [class.btn-sm]="_callToActionStyle === 'button-sm'"
        [class.btn-hero-size]="_callToActionStyle === 'button'"
        [class.btn-hero]="is.button && buttonStyle === 'hero'"
        [class.btn-secondary]="is.button && buttonStyle === 'secondary'"
        bgoOpenNewTab
      >
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="{icon: whatsappIcon, iconPosition: 'left'}"
        ></ng-container>
      </a>
    }
  }

  <ng-template let-icon="icon" let-iconPosition="iconPosition" #content>
    <ng-content></ng-content>
    <!-- hide the icon when it's being wrapped in a container too small to keep the button height -->
    @if (_callToActionStyle !== 'content') {
      <span
        class="inline-flex h-full flex-wrap-reverse justify-center overflow-hidden"
        [class.flex-row]="iconPosition === 'right'"
        [class.flex-row-reverse]="iconPosition === 'left'"
        [class.gap-md]="is.button"
        [class.gap-xs]="is.link"
      >
        <label class="flex h-full cursor-pointer flex-col justify-center"> {{ callToAction.label }} </label>
        @if (icon && showIcon) {
          <span class="flex h-full flex-col justify-center">
            <fa-icon [icon]="icon"></fa-icon>
          </span>
        }
      </span>
    }
  </ng-template>
}
